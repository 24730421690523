import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IconWithSpinner } from '@/core/IconWithSpinner.atom';
import { returnToState } from '@/utilities/utilities';

interface LoadErrorProps {
  header: string;
  message1: string;
  message2: string;
  retryInterval?: number;
}

export const LoadError: React.FunctionComponent<LoadErrorProps> = ({ header, message1, message2, retryInterval }) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (retryInterval) {
      const timeout = setTimeout(() => returnToState(), retryInterval);
      return () => clearTimeout(timeout);
    }
  }, [retryInterval]);

  return (
    <div className="flexColumnContainer flexCenter fullViewport splashScreen">
      <div className="container ml0 mr0">
        <div className="row flexJustifyCenter">
          <div className="span12">
            <div className="jumbotron text-center">
              <p>
                <img src="/img/Seeq_logo_darkBlue_sm.png" />
              </p>
              <h2 className="mb10">{t(header)}</h2>
              <p>
                <span>{t(message1)}</span>
              </p>
              <p>
                <span>{t(message2)}</span>
              </p>
              {retryInterval ? <IconWithSpinner testId="loadErrorLoadingIcon" spinning={true} /> : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
