import React from 'react';
import ReactDOM from 'react-dom';
import './i18n';
import 'core-js';
import 'regenerator-runtime/runtime';
import './styles';
import 'moment-duration-format';
import './wdyr'; // Note this must be the first import for it to work
import { Application } from '@/main/Application.page';

ReactDOM.render(<Application />, document.getElementById('sqApp') || document.createElement('div'));
